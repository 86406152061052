import { type FC, type PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { usePageTracking } from 'utils/ga';
import PublicLayout from 'components/templates/PublicLayout';

const Privacy: FC<PropsWithChildren> = () => {
  usePageTracking(); // ページビュー計測

  return (
    <PublicLayout>
      <Helmet>
        <title>
          プライバシーポリシー: サイト分析、レポートはGrowth Support
        </title>
      </Helmet>

      <section className="p-terms" id="terms">
        <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
          <header className="p-terms__header">
            <h1 className="p-terms__title text-gray-600">
              プライバシーポリシー
            </h1>
          </header>
          <div className="p-terms__body">
            <div className="p-terms__contents">
              <div className="p-terms__content">
                <p className="p-terms__description text-gray-600">
                  fives株式会社(以下、「当社」)は、マーケティングサービス、他自社サービスの業務を実施する上で、事業の用に供するすべての個人情報を適切に取扱うため、全従業者が遵守すべき行動基準として本プライバシーポリシーを定め、その遵守の徹底を図ることといたします。
                </p>
              </div>
              <div className="p-terms__content">
                <h2 className="p-article__title  text-gray-600">
                  第1条（個人情報の範囲、取得と利用）
                </h2>
                <ol>
                  <li className="p-article__description text-gray-600">
                    当方針における個人情報とは、当社が、当社の事業活動により直接または間接的に取得した、個人を識別できる情報を言い、その取得媒体と経路を問わないものとします。
                  </li>
                  <li className="p-article__description text-gray-600">
                    個人情報の取得・利用にあたっては、その目的を明確にし、その目的を達成するために必要な範囲を超えた個人情報の利用は致しません。また、目的外利用を行わないために、適切な管理措置を講じます。
                  </li>
                </ol>
              </div>
              <div className="p-terms__content">
                <h2 className="p-article__title  text-gray-600">
                  第2条（個人情報の管理）
                </h2>
                <p className="p-terms__description text-gray-600">
                  当社が取り扱う個人情報について、不正アクセス、紛失、破壊、改ざん、漏えいなどの危険を十分に認識し、合理的な安全対策を実施するとともに、問題が発生した場合は適切な是正措置を講じます。
                </p>
              </div>
              <div className="p-terms__content">
                <h2 className="p-article__title  text-gray-600">
                  第3条（個人情報の第三者提供）
                </h2>
                <ol>
                  <li className="p-article__description text-gray-600">
                    当社は、個人情報保護法等の法令に定めのある場合及び、警察機関、裁判所などの機関から開示請求があった場合を除き、取得した個人情報を本人の許可なく第三者に開示致しません。
                  </li>
                  <li className="p-article__description text-gray-600">
                    前項の定めにかかわらず，次に掲げる場合は第三者には該当しないものとします。
                  </li>
                  <ol>
                    <li className="p-article__description text-gray-600">
                      当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
                    </li>
                    <li className="p-article__description text-gray-600">
                      合併その他の事由による事業の承継に伴って個人情報が提供される場合
                    </li>
                  </ol>
                </ol>
              </div>
              <div className="p-terms__content">
                <h2 className="p-article__title  text-gray-600">
                  第4条（個人情報の開示、訂正および削除）
                </h2>
                <p className="p-terms__description text-gray-600">
                  当社は、取得した個人情報について、本人からの要請に応じて合理的範囲内で開示、訂正、削除に応じます。
                </p>
              </div>
              <div className="p-terms__content">
                <h2 className="p-article__title  text-gray-600">
                  第5条（個人情報に関するお問合わせ）
                </h2>
                <p className="p-terms__description text-gray-600">
                  当方針に関するお問い合わせは，下記までお願いいたします。
                </p>
                <p className="p-terms__description text-gray-600">
                  社名： fives株式会社
                  <br />
                  住所： 東京都練馬区練馬1-20-8 日建練馬ビル2F
                  <br />
                  サービス名： Growth Support
                  <br />
                  メールアドレス： info[at]growth-support.net
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PublicLayout>
  );
};

export default Privacy;
